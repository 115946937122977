<template>
  <b-overlay :show="loading" rounded="sm" class="loading-overlay">
    <div class="insurance-details" v-if="selectedInsurance">
      <div class="insurance-details__main">
        <div class="insurance-resume-container">
          <InsuranceResume :selectedInsurance="selectedInsurance" />
        </div>
        <div class="insurance-documents-content">
          <div class="insurance-status-container">
            <InsuranceStatus :status="selectedInsurance.status" />
          </div>
          <div class="insurance-documents-container">
            <InsuranceDocuments :selectedInsurance="selectedInsurance" />
          </div>
        </div>
      </div>
      <div class="insurance-history-container">
        <InsuranceHistory :loadById="selectedInsurance.load" />
      </div>
    </div>
    <InsuranceDetailSkeleton v-else />
  </b-overlay>
</template>

<script>
import InsuranceResume from "./components/InsuranceResume.vue";
import InsuranceStatus from "./components/InsuranceStatus.vue";
import InsuranceDocuments from "./components/InsuranceDocuments/Index.vue";
import InsuranceHistory from "./components/InsuranceHistory.vue";
import InsuranceDetailSkeleton from "./InsuranceDetailSkeleton.vue";

export default {
  name: "InsuranceDetail",
  components: {
    InsuranceResume,
    InsuranceStatus,
    InsuranceDocuments,
    InsuranceHistory,
    InsuranceDetailSkeleton,
  },
  data() {
    return {
      selectedInsurance: null,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.selectedInsurance = await this.getInsuranceDetailById();
    this.loading = false;
  },
  methods: {
    async getInsuranceDetailById() {
      const insurance = await this.$store.dispatch(
        "insurance/getInsuranceById",
        this.$route.params.id
      );
      return insurance;
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-details {
  &__main {
    display: flex;
    justify-content: space-between;
  }
}
.insurance-resume-container {
  padding-right: 10px;
}
.insurance-documents-content {
  width: 50%;
}
.insurance-status-container {
  margin: 10px 0px;
}
.loading-overlay {
  color: $color-primary-company;
}
@media (max-width: 800px) {
  .insurance-details__main {
    flex-direction: column;
  }
  .insurance-documents-content {
    width: 100%;
    margin-top: 20px;
  }
  ::v-deep .el-collapse-item__header {
    padding: 1% 3%;
  }
  ::v-deep .el-collapse-item__content {
    padding: 1% 2%;
  }
}
</style>
