<template>
  <div class="insurance-history">
    <el-collapse v-model="openedTabs">
      <el-collapse-item title="History" name="history">
        <el-timeline>
          <el-timeline-item
            v-for="(history, index) in histories"
            :key="index"
            placement="top"
            :timestamp="getDate(history.createdAt) + ' ' + getHour(history.createdAt)"
          >
            <p class="load-history__item">
              <strong>{{ history.user }}</strong>
              {{
                history.operationType.toLowerCase() === "dispache"
                  ? history.operationType.toLowerCase() + "d"
                  : history.operationType.toLowerCase()
              }}
            </p>
            <ul class="changes-list">
              <li
                v-for="(change, index) in getChanges(history.changes)"
                :key="index"
                class="changes-list__item"
              >
                <template>
                  - <strong>{{ change[0] }}: </strong> {{ change[1] }}
                </template>
              </li>
            </ul>
          </el-timeline-item>
        </el-timeline>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

export default {
  name: "InsuranceHistory",
  props: {
    loadById: Object,
  },
  data() {
    return {
      openedTabs: [],
      histories: null,
    };
  },
  async created() {
    this.histories = await this.$store.dispatch("history/getAllHistory", this.loadById.load_id);
    if (this.histories) {
      this.openedTabs.push("history");
    }
  },
  methods: {
    getDate(completeDate) {
      return dayjs(completeDate).format("MM/DD/YYYY");
    },
    getHour(completeDate) {
      return dayjs(completeDate).format("HH:mm");
    },
    getChanges(changes) {
      return Object.entries(changes);
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-history {
  &__item {
    margin: 0px;
  }
}

.changes-list {
  padding-left: 5px;
  &__item {
    list-style-type: none;
  }
}

::v-deep {
  .el-collapse-item__header {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-weight: bold;
    margin: 2% 0;
    @media (max-width: 800px) {
      display: flex;
      justify-content: space-between;
      padding: 1% 7%;
      margin: 2% 0 0 0;
    }
  }
  .el-collapse-item__content {
    background-color: $color-gray-light;
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-small-standart-text;
    padding-bottom: 1%;
    @media (max-width: 800px) {
      padding: 1% 3%;
    }
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-timeline-item__tail {
    border-left: 2px solid $color-border-container;
  }
  .el-timeline-item__node {
    background-color: $color-border-container;
  }
  .el-timeline-item__content {
    text-align: left;
    color: $color-primary-company;
  }
  .el-timeline-item__timestamp.is-top {
    color: $color-primary-company;
    font-weight: 600;
    text-align: left;
  }
}
</style>
