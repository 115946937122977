<template>
  <div class="insurance-resume">
    <div class="insurance-resume__shp">
      <strong>{{ selectedInsurance.load.shp }}</strong>
    </div>
    <ul class="menu-items">
      <li class="menu-items__item" v-for="(item, index) in insuranceResume" :key="index">
        <p>
          <strong>{{ item.title }}:</strong>
          <span v-if="item.title === 'Insurance value'">
            $ {{ $options.filters.insurancePrice(item.value) }}
          </span>
          <span v-if="item.title === 'Insurance cost'">
            $ {{ $options.filters.insurancePrice(item.value) }}
          </span>
          <span v-else-if="item.title === 'Sailing on/or about'">
            {{ $options.filters.dateFormat(item.value) }}
          </span>
          <span v-else-if="item.title !== 'Insurance value'">
            {{ item.value }}
          </span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "InsuranceResume",
  props: {
    selectedInsurance: Object,
  },
  data() {
    return {
      insuranceResume: [],
    };
  },
  watch: {
    selectedInsurance: {
      immediate: true,
      handler() {
        if (this.selectedInsurance) {
          this.assignInsuranceValues();
        }
      },
    },
  },
  methods: {
    assignInsuranceValues() {
      this.insuranceResume = [
        { title: "Conveyance", value: this.selectedInsurance.conveyance },
        { title: "Origin", value: this.selectedInsurance.origin_city },
        { title: "Destination", value: this.selectedInsurance.destination_city },
        { title: "Commodity", value: this.selectedInsurance.commodity },
        { title: "Condition", value: this.selectedInsurance.condition },
        { title: "Insurance cost", value: this.selectedInsurance.customer_cost },
        { title: "Insurance value", value: this.selectedInsurance.insured_value },
        { title: "Packing", value: this.selectedInsurance.packing },
        { title: "Type shipment", value: this.selectedInsurance.type_of_shipment },
        { title: "Airline", value: this.selectedInsurance.transportation_service },
        { title: "Airline & Flight", value: this.selectedInsurance.transportation_service_id },
        { title: "Marks or numbers", value: this.selectedInsurance.marks_or_numbers },
        { title: "Reference number", value: this.selectedInsurance.reference_number },
        { title: "Sailing on/or about", value: this.selectedInsurance.sailing_date },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-resume {
  &__shp {
    font-family: $font-family-portal;
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
  }
}
.menu-items {
  list-style: none;
  padding: 0px;
  margin: 0px;
  &__item {
    p {
      margin: 6px 0px;
      text-align: start;
      strong {
        margin-right: 7px;
      }
    }
  }
}
</style>
