<template>
  <div class="insurance-details-skeleton">
    <div class="insurance-details">
      <div class="insurance-left">
        <Skeleton class="mb-3 mt-3 insurance-details__item" width="70%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item insurance-details__item--short" width="50%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item insurance-details__item--short" width="50%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item insurance-details__item--short" width="50%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item insurance-details__item--short" width="50%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item" width="100%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item" width="100%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item" width="100%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item insurance-details__item--short" width="50%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item insurance-details__item--short" width="50%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item" width="80%"></Skeleton>
        <Skeleton class="mb-3 mt-3 insurance-details__item" width="100%"></Skeleton>
      </div>
      <div class="insurance-right">
        <div class="insurance-status-container">
          <Skeleton class="mb-3 mt-3" width="100%" height="50px"></Skeleton>
        </div>
        <div class="insurance-tabs-container">
          <Skeleton class="mb-3 mt-3" width="100%" height="30px"></Skeleton>
          <Skeleton class="mb-3 mt-3" width="100%" height="30px"></Skeleton>
          <Skeleton class="mb-3 mt-3" width="100%" height="30px"></Skeleton>
        </div>
        <div class="insurance-document-container">
          <Skeleton class="mb-3 mt-3" width="100%" height="180px"></Skeleton>
        </div>
      </div>
    </div>
    <div class="insurance-history">
      <Skeleton class="mb-3 mt-3 insurance-history__item" width="50%" height="130px"></Skeleton>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "InsuranceDetailSkeleton",
  components: {
    Skeleton,
  },
};
</script>

<style lang="css" scoped>
.insurance-details {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.insurance-right {
  width: 100%;
  padding: 5px;
}
.insurance-left {
  width: 100%;
  padding: 5px;
}
.insurance-tabs-container {
  display: flex;
  gap: 10px;
}

::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
@media (max-width: 800px) {
  .insurance-details {
    flex-direction: column;
  }
  .insurance-details__item {
    width: 50% !important;
  }
  .insurance-details__item--short {
    width: 30% !important;
  }
  .insurance-history__item {
    width: 100% !important;
  }
  .insurance-left {
    widows: 50%;
  }
}
</style>
