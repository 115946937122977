<template>
  <div class="insurance-status">
    <div class="image-container">
      <svg
        width="50"
        height="50"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 0C28.4983 0 28.9967 0.109976 29.4517 0.318932L49.8508 9.10605C52.2341 10.1288 54.0108 12.5153 53.9999 15.3967C53.9458 26.3064 49.5258 46.2671 30.86 55.3401C29.0508 56.22 26.9492 56.22 25.14 55.3401C6.47421 46.2671 2.05422 26.3064 2.00005 15.3967C1.98922 12.5153 3.76588 10.1288 6.14921 9.10605L26.5592 0.318932C27.0033 0.109976 27.5017 0 28 0Z"
          :fill="status === 'Issued' ? '#00B9CC' : '#c4c4c4'"
        />
        <g clip-path="url(#clip0_64_1373)">
          <path
            d="M39.4755 21.1465C40.173 21.7568 40.173 22.748 39.4755 23.3584L25.1898 35.8584C24.4922 36.4688 23.3594 36.4688 22.6619 35.8584L15.519 29.6084C14.8215 28.998 14.8215 28.0068 15.519 27.3965C16.2166 26.7861 17.3494 26.7861 18.0469 27.3965L23.9286 32.5381L36.9532 21.1465C37.6507 20.5361 38.7835 20.5361 39.4811 21.1465H39.4755Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_64_1373">
            <rect width="25" height="25" fill="white" transform="translate(15 16)" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="text-container" v-if="status === 'Issued'">
      <p><strong>Completed</strong></p>
    </div>
    <div class="text-container" v-if="status !== 'Issued'">
      <p><strong>Insurance booked</strong></p>
      <p class="text-container__text">
        Thank you so much for choosing us for covering your shipment. Your Certificate of
        Insurance is in progress and we will send you a notification when ready (less than 30
        minutes) besides you will be able to download it from our portal.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsuranceStatus",
  props: {
    status: String
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.insurance-status {
  display: flex;
  .text-container {
    text-align: start;
    &__text {
      font-size: 14px;
    }
    p {
      margin: 0px;
    }
  }
}
</style>
